import { ReactNode, useCallback } from "react";
import { IoCalendarClearOutline, IoHomeOutline } from "react-icons/io5";
import { RiBuildingLine } from "react-icons/ri";
import { PiMoneyLight } from "react-icons/pi";
import { IoIosNotificationsOutline } from "react-icons/io";
import { MdOutlineAccountCircle } from "react-icons/md";
import { CiSettings } from "react-icons/ci";
import { LuBookMinus } from "react-icons/lu";
import { useUserIdentityContext } from "@core/services/user-identity.context";
import { FaQuestion } from "react-icons/fa6";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { MdGroup } from "react-icons/md";
import config from "../../../config.json";

const activeClass =
  "bg-gradient-to-tr from-buster-green-200 to-buster-green-100 text-gray-800";
const hoverClass = "hover:bg-buster-green-100";
const newNavigation = (
  title: string,
  icon: ReactNode,
  path: string,
  isHidden?: boolean
) => {
  return {
    title,
    icon,
    path,
    activeClass,
    hoverClass,
    isHidden,
  };
};

export function useShellMenu() {
  const { userIsAdmin, getUser } = useUserIdentityContext();
  const homeNav = newNavigation("Home", <IoHomeOutline />, "");
  const requestNav = newNavigation("Request", <LuBookMinus />, "request");
  const eventNav = newNavigation("Event", <IoCalendarClearOutline />, "event");
  const branchNav = newNavigation("Branch", <RiBuildingLine />, "branch");
  const settingNav = newNavigation("Setting", <CiSettings />, "setting");
  const payrollNav = newNavigation("Payroll", <PiMoneyLight />, "payroll");
  const loanNav = newNavigation(
    "Loan",
    <FaMoneyBillTransfer />,
    "loan",
    !config.features.loan.page
  );
  const faqNav = newNavigation("Tutorial", <FaQuestion />, "faq");
  const groupNav = newNavigation(
    "Group",
    <MdGroup />,
    "group",
    !config.features.employeeGroup.page
  );
  const notificationNav = newNavigation(
    "Timeline",
    <IoIosNotificationsOutline />,
    "timeline"
  );
  const accountNav = newNavigation(
    "Employee",
    <MdOutlineAccountCircle />,
    "personnel"
  );

  const getDesktopNavigation = useCallback(() => {
    const navigation = [
      homeNav,
      eventNav,
      requestNav,
      payrollNav,
      settingNav,
      faqNav,
      notificationNav,
    ];

    if (userIsAdmin()) {
      navigation.splice(3, 0, branchNav);
      navigation.splice(4, 0, loanNav);
      navigation.splice(5, 0, accountNav);
      navigation.splice(6, 0, groupNav);
    } else {
      const branchInfoNav = newNavigation(
        "Branch",
        <RiBuildingLine />,
        `branch/${getUser()?.branchId}`
      );
      navigation.splice(3, 0, branchInfoNav);
    }

    const filteredNavigation = navigation.filter((item) => !item.isHidden);

    return filteredNavigation;
  }, [userIsAdmin, getUser]);

  return {
    homeNav,
    getDesktopNavigation,
  };
}
